import styled from "styled-components";
import { Table as TableAnt, Tag as TagAnt } from "antd";
import { ThreeDots } from "../../styles/Icons";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TotalText = styled.h1``;

export const ContentTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: auto;
`;

export const ThreeDotsIcon = styled(ThreeDots)`
  width: 20px;
  color: var(--orange-100);

  cursor: pointer;
`;

export const Table = styled(TableAnt)`
  width: 100%;
  height: 100%;
  overflow: scroll !important;

  .ant-table-content::-webkit-scrollbar {
    height: 8px !important;
  }

  .ant-table-content::-webkit-scrollbar-thumb {
    background-color: var(--orange-100) !important;
    border-radius: 4px !important;
  }

  *::-webkit-scrollbar {
    width: 10 !important;
    background: transparent !important;
  }

  .ant-table-content {
    overflow: scroll !important;

    ::-webkit-scrollbar {
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: "var(--orange-250)";
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      border-radius: 4px;
    }
  }
`;

export const Centralizer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const FormatName = styled.span`
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 300;
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Tag = styled(TagAnt)`
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 4px;
  padding: 5px 10px;
  color: ${({ color }) => (color === "gray" ? "#595959" : "var(--white)")};
  background-color: ${({ color }) =>
    color === "gray" ? "var(--white)" : "var(--orange-150)"};

  &:hover {
    background-color: ${({ color }) =>
      color === "gray" ? "#d9d9d9" : "var(--orange-150)"};
  }
`;

export const ContentClick = styled.a`
  text-decoration: underline;
  color: var(--orange-100);
`;
