import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../services/api";
import { verifyPermission } from "../../services/auth";
import { onlyNumbers } from "../../services/onlyNumber";

import { CompanyFocus } from "../../models/entities/companyFocus";
import { Company as CompanyModel } from "../../models/entities/company";

import Upload from "../../components/Upload";
import Spinner from "../../components/Spinner";

import { Input, Tooltip, notification } from "antd";

import {
  CancelButton,
  Col,
  Form,
  FormUpload,
  Modal,
  Row,
  SaveButton,
  Select,
  Option,
  Centralizer,
} from "./styles";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  company: CompanyModel | null;
  setCompany: Dispatch<SetStateAction<CompanyModel | null>>;
}

const FocusForm: React.FC<IProps> = ({
  visible,
  setVisible,
  setShouldSearch,
  company,
  setCompany,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    const formCompanyValues = async () => {
      if (company) {
        form.setFieldsValue({
          ...company,
        });
      } else {
        clearFields();
      }
    };

    if (visible) {
      formCompanyValues();
    }
  }, [visible]);

  const clearFields = async () => {
    setFile(null);
    setCompany(null);
    await form.resetFields();
  };

  const handleState = (name: string, value) => {
    let valueFormat = value;
    if (typeof valueFormat === "string") {
      valueFormat = valueFormat.toLowerCase();
    }

    setCompany((oldValues) => ({ ...oldValues, [name]: valueFormat }));
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  const payloadStringTrim = (payload) => {
    let trimPayload = payload;
    for (const objProp in trimPayload) {
      if (typeof trimPayload[objProp] === "string") {
        trimPayload[objProp] = trimPayload[objProp].trim();
      }
    }
    return trimPayload;
  };

  const capitalize = (value) => {
    return value
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (string) => string.toUpperCase());
  };

  const onFinish = async () => {
    if (!company?.company_id_focus && !file) {
      return notification.warning({
        message: "Certificado é obrigatório",
        description:
          "O certificado é obrigatório durante o cadastro de empresa na focus",
        duration: 5,
      });
    }
    await form.validateFields();

    Modal.confirm({
      title: company?.id ? "Edição de empresa" : "Cadastro de empresa",
      content: `Deseja continuar e ${
        company?.id ? "editar" : "cadastrar"
      } esta empresa?`,
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        try {
          setLoading(true);

          const method = company?.company_id_focus ? "put" : "post";
          const url = `/company/focus/${company?.id}`;

          const formPayload = form.getFieldsValue();

          let payload: CompanyFocus = {
            nome: capitalize(company?.company_name),
            nome_fantasia: capitalize(company?.emitente_nome_fantasia),
            bairro: company?.district,
            cep: +(onlyNumbers(company?.cep) || 0),
            cnpj: company?.cnpj,
            complemento: company?.complement,
            email: company?.email,
            inscricao_estadual: +(onlyNumbers(company?.emitente_inscricao_estadual) || 0),
            inscricao_municipal: +(onlyNumbers(company?.emitente_inscricao_municipal) || 0),
            logradouro: company?.address,
            numero: company?.number,
            regime_tributario: +(company?.emitente_regime_tributario || 0),
            telefone: company?.telefone,
            municipio: company?.city,
            uf: company?.emitente_uf,
            enviar_email_destinatario: company?.enviar_email_destinatario,
            discrimina_impostos: company?.discrimina_impostos,

            habilita_nfce: formPayload.habilita_nfce,
            habilita_nfe: formPayload.habilita_nfe,
            arquivo_certificado_base64: "",
            senha_certificado: formPayload.password_certificate,
            csc_nfce_producao: formPayload.token_nfce_production,
            id_token_nfce_producao: formPayload.token_nfce_production_id,
            csc_nfce_homologacao: formPayload.token_nfce_homologation,
            id_token_nfce_homologacao: formPayload.token_nfce_homologation_id,
          };

          payload = payloadStringTrim(payload);

          if (file) {
            const base64File: any = await toBase64(file);
            payload.arquivo_certificado_base64 = base64File
              ?.toString()
              ?.replace("data:application/x-pkcs12;base64,", "");
          }

          await api[method](url, payload);

          notification.success({
            message: `Empresa ${
              company?.company_id_focus ? "editada" : "cadastrada"
            } na Focus com sucesso`,
            duration: 3,
          });

          clearFields();
          setShouldSearch(true);
          setVisible(false);
        } catch (error: any) {
          let _description = error?.response?.data?.message || "";
          if (error?.response?.data?.statusCode === 400) {
            _description = error?.response?.data?.message[0] || "";
          }

          notification.error({
            message: `Erro ao ${
              company?.company_id_focus ? "editar" : "cadastrar"
            } empresa na Focus`,
            description: _description,
            duration: 3,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const onCancel = () => {
    Modal.confirm({
      title: "Deseja realmente cancelar?",
      content: "As informações preenchidas serão descartadas.",
      okText: "Sim",
      centered: true,
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        await clearFields();
        setVisible(false);
      },
    });
  };

  return (
    <Modal
      title={
        company?.company_id_focus
          ? "Editar informações"
          : "Cadastrar empresa na Focus"
      }
      centered
      closable={false}
      visible={visible}
      confirmLoading={loading}
      width={900}
      destroyOnClose
      footer={[
        <CancelButton onClick={() => onCancel()}>
          Cancelar cadastro
        </CancelButton>,
        <SaveButton
          onClick={() => onFinish()}
          loading={loading}
          disabled={loading}
        >
          Confirmar cadastro
        </SaveButton>,
      ]}
    >
      {loading ? (
        <Centralizer>
          <Spinner />
        </Centralizer>
      ) : (
        <Form layout="vertical" initialValues={{ remember: false }} form={form}>
          <Row gutter={4}>
            <Col sm={10} xs={24}>
              <FormUpload label="Certificado">
                <Upload file={file} setFile={setFile} typeFile=".pfx,.p12" />
              </FormUpload>
              {company?.company_id_focus && (
                <Tooltip
                  title={
                    "Adicione outro arquivo caso queira substituir o certificado anterior"
                  }
                >
                  <span className="span-certificate">Certificado já enviado.</span>
                </Tooltip>
              )}
            </Col>
            <Col sm={6} xs={24}>
              <Form.Item
                label="Senha Certificado"
                name="password_certificate"
                rules={[
                  {
                    required: company?.company_id_focus ? false : true,
                    message: "A Senha do Certificado é obrigatória.",
                  },
                ]}
              >
                <Input
                  disabled={
                    !verifyPermission("company.edit_contract_information")
                  }
                  type="password"
                  name="password_certificate"
                  placeholder="Digite a senha do certificado"
                  onChange={({ target: { value } }) =>
                    handleState("password_certificate", value)
                  }
                />
              </Form.Item>
            </Col>

            <Col sm={4} xs={12}>
              <Form.Item
                label="NFCe"
                name="habilita_nfce"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  placeholder="NFCe"
                  onChange={(value) => handleState("habilita_nfce", value)}
                >
                  <Option value={true}>Sim</Option>
                  <Option value={false}>Não</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={4} xs={12}>
              <Form.Item
                label="NFe"
                name="habilita_nfe"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  placeholder="NFe"
                  onChange={(value) => handleState("habilita_nfe", value)}
                >
                  <Option value={true}>Sim</Option>
                  <Option value={false}>Não</Option>
                </Select>
              </Form.Item>
            </Col>

            {company?.habilita_nfce && (
              <Row gutter={4}>
                <Col sm={12}>
                  Homologação
                  <Row gutter={4}>
                    <Col sm={12}>
                      <Form.Item
                        label="ID Token"
                        name="token_nfce_homologation_id"
                      >
                        <Input
                          type="text"
                          name="token_nfce_homologation_id"
                          placeholder="ID Token"
                          onChange={({ target: { value } }) =>
                            handleState("token_nfce_homologation_id", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <Form.Item label="CSC" name="token_nfce_homologation">
                        <Input
                          type="text"
                          name="token_nfce_homologation"
                          placeholder="CSC"
                          onChange={({ target: { value } }) =>
                            handleState("token_nfce_homologation", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  Produção
                  <Row gutter={4}>
                    <Col sm={12}>
                      <Form.Item
                        label="ID Token"
                        name="token_nfce_production_id"
                        rules={[
                          { required: true, message: "ID é obrigatório" },
                        ]}
                      >
                        <Input
                          type="text"
                          name="token_nfce_production_id"
                          placeholder="ID Token"
                          onChange={({ target: { value } }) =>
                            handleState("token_nfce_production_id", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <Form.Item
                        label="CSC"
                        name="token_nfce_production"
                        rules={[
                          {
                            required: true,
                            message: "CSC é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="token_nfce_production"
                          placeholder="CSC"
                          onChange={({ target: { value } }) =>
                            handleState("token_nfce_production", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Row>

          <Row gutter={4}>
            <Col sm={12}>
              <Form.Item
                label="Token Homologação"
                name="token_focus_homologation"
              >
                <Input
                  type="password"
                  name="token_focus_homologation"
                  placeholder="Token Homologação"
                  onChange={({ target: { value } }) =>
                    handleState("token_focus_homologation", value)
                  }
                  readOnly
                />
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label="Token Produção" name="token_focus_production">
                <Input
                  type="password"
                  name="token_nfce_production_focus"
                  placeholder="Token Produção"
                  onChange={({ target: { value } }) =>
                    handleState("token_nfce_production_focus", value)
                  }
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default FocusForm;